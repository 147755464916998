@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "proximaNova";
  src: url("../public/fonts/ProximaNova-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proximaNovaBold";
  src: url("../public/fonts/ProximaNova-Bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "proximaNovaSemiBold";
  src: url("../public/fonts/ProximaNova-Semibold-11.otf");
  font-weight: bold;
  font-style: normal;
}
